import { reactive, toRefs, getCurrentInstance, ref, computed, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { fnModalAlert } from '@/components/common/modal/alert'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters } = useStore()
  const query = route.query
  const clickCatch = ref(0)
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    noticeId: query.noticeId,
    detail: {
      contents: '',
      nextId: '',
      nextTitle: '',
      noticeId: '',
      noticeType: '',
      prevId: '',
      prevTitle: '',
      regDt: '',
      title: ''
    },
    file: {
      fileLoc: '',
      noticeId: '',
      saveFileNm: ''
    },
    clickCount: computed(() => clickCatch.value)
  })

  const onClickCatch = () => {
    clickCatch.value += 1
    showEventModal()
  }

  onUnmounted(() => {
    if(document.getElementById('clickEvent001')) {
      document.getElementById('clickEvent001').removeEventListener('click', onClickCatch)
    }
  })

  const showEventModal = () => {
    if(proxy.$Util.isEmpty(state.userData)) return alert('이벤트에 참여하시려면 로그인이 필요합니다.')
    const payload = {
      component: proxy.$modalAlertNames.CUSTOM_EVENT_INPUT,
      data: {
        eventTitle: '베이징 동계 올림픽에서 대한민국의 예상 최종 순위는 _위이다.',
        inputPlaceholder: '숫자로 입력해주세요.'
      },
      callback: async (answer) => {
        const res = await proxy.$CustomerSvc.postEventOlympicQuizAnswer({ answer })
        alert(res.resultMsg)
      }
    }
    fnModalAlert(payload)
  }


  const fnDetail = async () => {
    const res = await proxy.$CustomerSvc.postNoticeDetail(state)
    if (res.resultCode === '0000') {
      proxy.$_.merge(state.detail, res.detail)
      proxy.$_.merge(state.file, res.file)
    }
  }
  const fnPage = async noticeId => {
    if (proxy.$Util.isEmpty(noticeId)) return
    await router.replace({ path: '/cs/notice/view', query: { noticeId } })
  }
  const init = async () => {
    await fnDetail()
    if(document.getElementById('clickEvent001')) {
      document.getElementById('clickEvent001').addEventListener('click', onClickCatch)
    }
  }
  /** init **/
  init()
  return { ...toRefs(state), fnDetail, fnPage, showEventModal }
}
