<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="lump">
            <div class="notice_list_li">
              <div class="notice_list_ttl">
                <div class="left">
                  <span class="cate sec_color">{{ detail.noticeType }}</span>
                  <span>{{ detail.title }}</span>
                </div>
                <div class="right"></div>
              </div>
              <div class="notice_list_con">
                <div class="left">
                  <div class="date">{{ detail.regDt }}</div>
                </div>
                <div class="right"></div>
              </div>
            </div>
          </div>
          <div class="lump">
            <div class="notice_detail_con">
              <div class="editor">
                <div v-html="detail.contents"></div>
              </div>
              <div class="upload_file" v-if="!$Util.isEmpty(file.fileLoc)">
                <span>첨부파일</span>
                <a
                  class="upload_file_name"
                  :href="file.fileLoc"
                  target="_blank"
                  >{{ file.saveFileNm }}</a
                >
              </div>
            </div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button
                class="btn bo"
                @click="fnPage(detail.prevId)"
                v-if="!$Util.isEmpty(detail.prevId)"
              >
                이전글
              </button>
              <button
                class="btn bo"
                @click="fnPage(detail.nextId)"
                v-if="!$Util.isEmpty(detail.nextId)"
              >
                다음글
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'cs-notice-view-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
